import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'

import styles from './list.styles'
import { useTexts } from '../../../texts'
import { Icons } from '../../../assets'
import ExpandableContent from './ExpandableContent'

const expandingListPropTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    .isRequired,
  mainData: PropTypes.array.isRequired,
  renderContent: PropTypes.func.isRequired,
  seeAllLabel: PropTypes.string,
  seeLessLabel: PropTypes.string,
  onShowEv: PropTypes.func,
  onClickSeeAll: PropTypes.func,
  onClickSeeLess: PropTypes.func,
}

const ExpandingList = ({
  data,
  mainData,
  renderContent,
  seeAllLabel,
  seeLessLabel,
  onShowEv = () => {},
  onClickSeeAll = () => {},
  onClickSeeLess = () => {},
}) => {
  const texts = useTexts()
  const secondaryData = data.filter(
    (datum) => !mainData.find((el) => el.id === datum.id),
  )

  const [isExpanded, setIsExpanded] = useState(false)

  const showAllButton = {
    label: seeAllLabel ?? texts.seeAll(),
    onClick: () => {
      onClickSeeAll()
      setIsExpanded(true)
    },
    icon: <Icons.ChevronDown />,
  }
  const showLessButton = {
    label: seeLessLabel ?? texts.seeLess(),
    onClick: () => {
      onClickSeeLess()
      setIsExpanded(false)
    },
    icon: <Icons.ChevronUp />,
  }
  const button = isExpanded ? showLessButton : showAllButton

  useEffect(() => {
    onShowEv()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={styles.root}>
      {renderContent(mainData)}
      <ExpandableContent expanded={isExpanded}>
        {renderContent(secondaryData)}
      </ExpandableContent>
      {data.length <= mainData.length ? null : (
        <Button
          fullWidth
          sx={styles.button}
          className={!isExpanded && 'not-expanded'}
          onClick={button.onClick}
          endIcon={button.icon}
        >
          {button.label}
        </Button>
      )}
    </Box>
  )
}

ExpandingList.propTypes = expandingListPropTypes

export default ExpandingList
