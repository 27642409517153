import jwtDecode from 'jwt-decode'

import { ProductFruits } from '../thirdPartyIntegration'
import {
  URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS,
  AUTH_PARAMS,
} from './constants'
import {
  login as loginService,
  logout as logoutService,
  refreshSession as refreshSessionService,
} from './auth'

const ID_TOKEN_STORAGE_KEY = 'payper_id_token'
const ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY =
  'payper_id_token_expiration_timestamp'
const ACCESS_TOKEN_STORAGE_KEY = 'payper_access_token'
const ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY =
  'payper_access_token_expiration_timestamp'
const REFRESH_TOKEN_STORAGE_KEY = 'payper_refresh_token'

const BONUS_STORAGE_KEY = 'payper_bonus_token'

const HIDE_WIZARD_KEY = 'payper_hide_home_screen_wizard'

export const setSessionTokens = (tokens) => {
  window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, tokens.refreshToken)
  window.localStorage.setItem(ID_TOKEN_STORAGE_KEY, tokens.idToken)
  window.localStorage.setItem(
    ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY,
    JSON.stringify(tokens.idTokenExpiresAt),
  )
  window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, tokens.accessToken)
  window.localStorage.setItem(
    ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY,
    JSON.stringify(tokens.accessTokenExpiresAt),
  )
}
export const storeSession = (tokens) => {
  setSessionTokens(tokens)
  document.dispatchEvent(new Event('sessionStored'))
}

export const clearLocalStorage = () => {
  window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ID_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ID_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)
  window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
  window.localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)

  window.localStorage.removeItem(BONUS_STORAGE_KEY)
  window.localStorage.removeItem(HIDE_WIZARD_KEY)
}

export const getHideWizardFlag = () =>
  window.localStorage.getItem(HIDE_WIZARD_KEY)
export const setHideWizardFlag = (value) =>
  window.localStorage.setItem(HIDE_WIZARD_KEY, value)

export const setBonusToken = (value) =>
  window.localStorage.setItem(BONUS_STORAGE_KEY, value)
export const removeBonusToken = () =>
  window.localStorage.removeItem(BONUS_STORAGE_KEY)
export const getBonusToken = () =>
  window.localStorage.getItem(BONUS_STORAGE_KEY)

export const getAccessTokenExpiration = () =>
  window.localStorage.getItem(ACCESS_TOKEN_EXPIRATION_TIMESTAMP_STORAGE_KEY)

export const getIdToken = () =>
  window.localStorage.getItem(ID_TOKEN_STORAGE_KEY)

export const getAccessToken = () =>
  window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)

export const getRefreshToken = () =>
  window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)

export const isAuthenticated = () => !!getRefreshToken() && !!getAccessToken()

const isTokenExpired = (tokenExpiration) => {
  const nowTimestampInSecs = new Date().getTime() / 1000
  return nowTimestampInSecs > tokenExpiration
}

export const hasValidAccessToken = () =>
  !isTokenExpired(getAccessTokenExpiration())

export const getUserInfoFromIdToken = () => {
  if (!Boolean(getIdToken())) return null
  return jwtDecode(getIdToken())
}

export const getUserSub = () => {
  return getUserInfoFromIdToken()?.sub ?? null
}

export const getIsIdpAccount = () => {
  const userInfo = getUserInfoFromIdToken()
  return Boolean(userInfo?.identities?.length)
}

export const login = async (username, password) => {
  const userData = await loginService(username, password)
  storeSession(userData)
  return getUserSub()
}

export const logout = async () => {
  const refreshToken = getRefreshToken()

  await logoutService(refreshToken)
  clearLocalStorage()
  ProductFruits.destroy()
  window.location.reload()
}

export const refreshSession = async () => {
  const { email } = getUserInfoFromIdToken()
  const tokens = await refreshSessionService(email, getRefreshToken())
  if (tokens) {
    storeSession(tokens)
  } else {
    logout()
  }
  return tokens
}

export const refreshSessionFromExternalData = async (email, refreshToken) => {
  const tokens = await refreshSessionService(email, refreshToken)
  if (tokens) {
    storeSession(tokens)
  } else {
    logout()
  }
  return tokens
}

export const getAuthParams = () => {
  return {
    [AUTH_PARAMS.REFRESH_TOKEN]: getRefreshToken(),
    [AUTH_PARAMS.EMAIL]: getUserInfoFromIdToken()?.email,
  }
}

export const getAccessParams = () => {
  return {
    [URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.REFRESH_TOKEN]: getRefreshToken(),
    [URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.EMAIL]:
      getUserInfoFromIdToken()?.email,
    [URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.ACCESS_TOKEN]: '1',
  }
}

const appendPayperWidgetSeed = (searchParams) => {
  searchParams.append(URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.ACCESS_TOKEN, '1')
  searchParams.append(
    URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.WIDGET_VISIBLE_FLAG,
    '1',
  )
}
const appendSession = (searchParams) => {
  searchParams.append(
    URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.REFRESH_TOKEN,
    getRefreshToken(),
  )

  const { email } = getUserInfoFromIdToken()
  searchParams.append(URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.EMAIL, email)
}

export const appendPayperWidgetSeedToURL = (url) => {
  const redirectUrl = new URL(url)
  const searchParams = new URLSearchParams(redirectUrl.search)

  appendPayperWidgetSeed(searchParams)

  return `${redirectUrl.origin}${
    redirectUrl.pathname ?? ''
  }?${searchParams.toString()}`
}

export const appendAccessParamsToURL = (url) => {
  const redirectUrl = new URL(url)
  const searchParams = new URLSearchParams(redirectUrl.search)

  appendSession(searchParams)
  appendPayperWidgetSeed(searchParams)

  return `${redirectUrl.origin}${
    redirectUrl.pathname ?? ''
  }?${searchParams.toString()}`
}

export const appendPurchasedArticleAccessParamsToURL = (url, authToken) => {
  const redirectUrl = new URL(url)
  const searchParams = new URLSearchParams(redirectUrl.search)

  appendSession(searchParams)
  appendPayperWidgetSeed(searchParams)

  if (Boolean(authToken)) {
    searchParams.append(
      URL_SEARCH_PARAMS_REDIRECT_ACCESS_KEYS.ARTICLE_AUTH_TOKEN,
      authToken,
    )
  }

  return `${redirectUrl.origin}${
    redirectUrl.pathname ?? ''
  }?${searchParams.toString()}`
}
