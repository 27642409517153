import { eventsEndpoint } from '../config'
import { getCookie } from '../business/cookies'

const ANALYTICS_COOKIE = '_ga'

const getClientIdAnalyticsCookie = () => {
  const rawGaValue = getCookie(ANALYTICS_COOKIE)
  if (Boolean(rawGaValue)) {
    return rawGaValue.split('.').slice(-2).join('.')
  } else {
    return null
  }
}

const sendPayperEventList = async (eventList) => {
  const clientId = getClientIdAnalyticsCookie()
  if (clientId) {
    const response = await fetch(`${eventsEndpoint}/set-event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: clientId,
        events: eventList,
      }),
    })
    if (!response.ok) {
      throw new Error()
    }
  }
}

const sendPayperEvent = async (eventName, detail) => {
  try {
    await sendPayperEventList([
      {
        name: eventName,
        params: detail,
      },
    ])
  } catch (error) {
    console.error(`Payper: error sending event ${eventName}`)
  }
}

const dispatchEvent = (eventName, detail) => {
  sendPayperEvent(eventName, detail)
}

export default dispatchEvent

export const dispatchAnaltyicsEvent = (eventName, params = {}) => {
  document.dispatchEvent(
    new CustomEvent('payperAnalyticsEvent', {
      detail: {
        name: eventName,
        ...params,
      },
    }),
  )
}

export * as EVENTS_NAMES from './eventsNames.json'
