import { Session, User } from './index'

const recoverSearchParam = (paramName) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const value = urlSearchParams.get(paramName)
  if (value !== null) {
    urlSearchParams.delete(paramName)

    // NOTE: Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}${
      window.location.hash
    }`
    window.history.replaceState(null, '', newUrl)
  }
  return value
}

const readBonusToken = () => {
  const bonusToken = Session.getBonusToken()
  Session.removeBonusToken()
  return bonusToken
}

const BONUS_URL_SEARCH_PARAM = 'bonus'

const useBonusCreation = () => {
  const storeBonusToken = () => {
    const bonusToken = recoverSearchParam(BONUS_URL_SEARCH_PARAM)
    if (bonusToken !== null) {
      Session.setBonusToken(bonusToken)
    }
  }

  const applyBonus = async () => {
    const bonusToken = readBonusToken()
    if (bonusToken !== null) {
      try {
        await User.createBonus(bonusToken)
      } catch (error) {
        console.error(
          'Payper: se ha producido un error durate la creación del bono',
        )
      }
    }
  }

  return { storeBonusToken, applyBonus }
}

export default useBonusCreation
