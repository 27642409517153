import { useTheme, useMediaQuery } from '@mui/material'

import EVENTS_NAMES from '../../analytics/eventsNames.json'
import { openMediaLink } from '../utils'
import { ASSOCIATED_MEDIA_DATA, MAGAZINES_DATA } from '../constants'

export const LIST_TYPE = {
  SHORTENED: 'SHORTENED',
  EXPANDING: 'EXPANDING',
  FULL: 'FULL',
}

export const MIN_NUMBER_FOR_REDUCED_LIST_MOBILE = 11
export const MIN_NUMBER_FOR_REDUCED_LIST_DESKTOP = 13

export const MIN_NUMBER_FOR_NEW_PAGE_LIST_MOBILE = 19
export const MIN_NUMBER_FOR_NEW_PAGE_LIST_DESKTOP = 22

const DEFAULT_MEDIA_BUTTONS_CONFIG = {
  minNumberForReducedListMobile: MIN_NUMBER_FOR_REDUCED_LIST_MOBILE,
  minNumberForReducedListDesktop: MIN_NUMBER_FOR_REDUCED_LIST_DESKTOP,
  minNumberForNewPageListMobile: MIN_NUMBER_FOR_NEW_PAGE_LIST_MOBILE,
  minNumberForNewPageListDesktop: MIN_NUMBER_FOR_NEW_PAGE_LIST_DESKTOP,
}

export const useDynamicMediaButtons = (
  data = { newspapers: ASSOCIATED_MEDIA_DATA, magazines: MAGAZINES_DATA },
  config = DEFAULT_MEDIA_BUTTONS_CONFIG,
  clickMediaEvCustomData = {},
) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const { newspapers, magazines } = data

  const MIN_NUMBER_FOR_NEW_PAGE_LIST = isDesktop
    ? config.minNumberForNewPageListDesktop
    : config.minNumberForNewPageListMobile

  const MIN_NUMBER_FOR_REDUCED_LIST = isDesktop
    ? config.minNumberForReducedListDesktop
    : config.minNumberForReducedListMobile

  const mobileCoreMedia = newspapers.filter((media) => media.isCoreInMobile)
  const desktopCoreMedia = newspapers.filter((media) => media.isCoreInDesktop)

  const handleOpenMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
          mediaName: mediaData.name,
          ...clickMediaEvCustomData,
        },
      }),
    )

    openMediaLink(mediaData.domain, document.location.pathname)
  }

  return {
    magazines,
    newspapers,
    coreNewspapers: isDesktop ? desktopCoreMedia : mobileCoreMedia,
    onClickMedia: handleOpenMediaPage,
    listType:
      newspapers.length >= MIN_NUMBER_FOR_NEW_PAGE_LIST
        ? LIST_TYPE.SHORTENED
        : newspapers.length >= MIN_NUMBER_FOR_REDUCED_LIST
        ? LIST_TYPE.EXPANDING
        : LIST_TYPE.FULL,
  }
}

const useMediaButtons = (clickMediaEvCustomData) =>
  useDynamicMediaButtons(
    { newspapers: ASSOCIATED_MEDIA_DATA, magazines: MAGAZINES_DATA },
    DEFAULT_MEDIA_BUTTONS_CONFIG,
    clickMediaEvCustomData,
  )

export default useMediaButtons
