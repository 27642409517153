import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Auth, Session } from '../../../business'
import { CircularProgress } from '../../components'
import { AUTHENTICATED_DEFAULT_ROUTE } from '../../constants'
import Error from '../Error'

const AccessThirdParty = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      const authenticateWithRace = async (userId, token) => {
        try {
          Session.clearLocalStorage()
          const authData = await Auth.authenticateRaceUser(userId, token)
          Session.storeSession(authData)
          navigate(AUTHENTICATED_DEFAULT_ROUTE)
        } catch (error) {
          if (error instanceof Auth.InvalidCredentialsError) {
            setError({ status: 401 })
          } else {
            setError({ status: 500 })
          }
        }
      }

      if (params.thirdPartyName === 'race') {
        const searchParams = new URLSearchParams(window.location.search)
        const userId = searchParams.get('id')
        const token = searchParams.get('token')

        if (!userId || !token) {
          setError({ status: 401 })
        } else {
          await authenticateWithRace(userId, token)
        }
      } else {
        setError({ status: 500 })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  if (Boolean(error)) return <Error error={error} />
  return <CircularProgress height='80vh' />
}

export default AccessThirdParty
