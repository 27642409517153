import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { dispatchAnaltyicsEvent, EVENTS_NAMES } from '../../../../analytics'
import { useTexts } from '../../../../texts'
import { PageBlock } from '../../../Layout'
import { AssociatedMediaData } from '../../../propTypes'
import useMediaButtons, { LIST_TYPE } from '../../useMediaButtons'
import {
  ShortenedList,
  ExpandingList,
  AssociatedMedia,
} from '../../../components'

const associatedMediaPropTypes = {
  media: PropTypes.arrayOf(AssociatedMediaData),
  onClickMedia: PropTypes.func,
  navigateToNewspapers: PropTypes.func,
}

const AssociatedMediaDynamicList = () => {
  const navigate = useNavigate()

  const navigateToNewspapers = () => {
    dispatchAnaltyicsEvent({
      name: EVENTS_NAMES.PD_CLICK_SHOW_ALL_MEDIA_NEW_PAGE,
    })
    navigate('newspapers')
  }

  return (
    <AssociatedMediaDynamicListView
      onClickNavigateToNewspapers={navigateToNewspapers}
      {...useMediaButtons()}
    />
  )
}

export const AssociatedMediaDynamicListView = ({
  onClickNavigateToNewspapers,
  newspapers,
  coreNewspapers,
  onClickMedia,
  listType,
}) => {
  const texts = useTexts()

  return (
    <PageBlock title={texts.newspapers()}>
      {listType === LIST_TYPE.EXPANDING ? (
        <ExpandingList
          data={newspapers}
          mainData={coreNewspapers}
          renderContent={(data) => (
            <AssociatedMedia media={data} onClickMedia={onClickMedia} />
          )}
          seeAllLabel={texts.showAllMedias()}
          onShowEv={() =>
            dispatchAnaltyicsEvent({
              name: EVENTS_NAMES.PD_SHOW_MEDIA_EXPANDING,
            })
          }
          onClickSeeAll={() =>
            dispatchAnaltyicsEvent({
              name: EVENTS_NAMES.PD_CLICK_SHOW_ALL_MEDIA,
            })
          }
          onClickSeeLess={() =>
            dispatchAnaltyicsEvent({
              name: EVENTS_NAMES.PD_CLICK_SHOW_LESS_MEDIA,
            })
          }
        />
      ) : listType === LIST_TYPE.SHORTENED ? (
        <ShortenedList
          cta={{
            label: texts.showAllMedias(),
            onClick: onClickNavigateToNewspapers,
          }}
        >
          <AssociatedMedia media={coreNewspapers} onClickMedia={onClickMedia} />
        </ShortenedList>
      ) : (
        <AssociatedMedia media={newspapers} onClickMedia={onClickMedia} />
      )}
    </PageBlock>
  )
}

AssociatedMediaDynamicList.propTypes = associatedMediaPropTypes

export default AssociatedMediaDynamicList
