import { useLayoutEffect } from 'react'
import { Box } from '@mui/material'

import { useTexts } from '../../../texts'
import { AssociatedMedia } from '../../components'
import { PageContent, PageBlock } from '../../Layout'
import useMediaButtons from '../useMediaButtons'

const styles = {
  appBar: {
    width: '100%',
    backgroundColor: 'primary.main',
  },
}

const Home = () => {
  const texts = useTexts()
  const { newspapers, onClickMedia } = useMediaButtons()

  useLayoutEffect(() => {
    const appBarHeight = document
      .getElementById('public-header')
      ?.getBoundingClientRect()?.height

    if (Boolean(appBarHeight)) {
      document.getElementById('header-public-background').style.height =
        appBarHeight.toString() + 'px'
    }
  }, [])

  return (
    <>
      <Box sx={styles.appBar} id='header-public-background' />
      <PageContent>
        <PageBlock title={texts.newspapers()}>
          <AssociatedMedia media={newspapers} onClickMedia={onClickMedia} />
        </PageBlock>
      </PageContent>
    </>
  )
}

export default Home
