import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { AssociatedMediaData } from '../propTypes'
import { MediaButton } from '.'

const styles = {
  root: (theme) => ({
    width: `min(100%, var(--content-M-max-width))`,
    mx: 'auto',

    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: (theme) => theme.spacing(1),
    gridRowGap: (theme) => theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: (theme) => theme.spacing(2),
    },
  }),
}

const associatedMediaPropTypes = {
  media: PropTypes.arrayOf(AssociatedMediaData),
  onClickMedia: PropTypes.func,
}

const AssociatedMedia = ({ media = [], onClickMedia }) => {
  return (
    <Box sx={styles.root}>
      {media.map((mediaData, i) => (
        <MediaButton
          key={mediaData?.id ?? i}
          onClick={onClickMedia}
          data={mediaData}
        />
      ))}
    </Box>
  )
}

AssociatedMedia.propTypes = associatedMediaPropTypes

export default AssociatedMedia
