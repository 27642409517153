import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { User, useFetch } from '../../../../business'
import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { useTexts } from '../../../../texts'
import { AUTHENTICATED_DEFAULT_ROUTE } from '../../../constants'
import { CircularProgress } from '../../../components'
import View from './View'

const B2B_PAYPER_WEB = 'https://www.bepayper.com/'

const analyticsDispatch = (eventName, params = {}) => {
  document.dispatchEvent(
    new CustomEvent('payperAnalyticsPrivateEvent', {
      detail: {
        name: eventName,
        ...params,
      },
    }),
  )
}

const openUrl = ({ url, target = '_self' }) => {
  window.open(url, target, {
    rel: 'noreferrer noopener',
  })
}

const DEFAULT_CONTENT = (texts) => {
  const label = texts.endOfServiceActionLableDefault()
  const url = B2B_PAYPER_WEB

  return {
    text1: texts.endOfServiceText1Default(),
    text2: texts.endOfServiceText2Default(),
    action: {
      label,
      onClick: () => {
        analyticsDispatch(EVENTS_NAMES.PD_CLICK_EOS)
        openUrl({ url })
      },
    },
  }
}

export const getContentByPartner = (partner, texts) => {
  if (!Boolean(partner)) return DEFAULT_CONTENT(texts)

  let content = {}
  content.text1 = texts.endOfServiceText1Partner(partner?.name)
  content.text2 = partner?.configuration?.endOfService?.claim
  if (!!partner?.configuration?.endOfService?.cta) {
    const { label, url } = partner?.configuration?.endOfService?.cta
    content.action = {
      label,
      onClick: () => {
        analyticsDispatch(EVENTS_NAMES.PD_CLICK_EOS, {
          partnerId: partner.id,
        })
        openUrl({ url })
      },
    }
  }
  return content
}

const EndOfService = () => {
  const texts = useTexts()
  const navigate = useNavigate()

  const { isLoading, data: partner } = useFetch(User.fetchLastPartner)
  const { bonusState } = User.useBonus()

  useEffect(() => {
    if (!bonusState.isLoading && Boolean(bonusState.data)) {
      navigate(AUTHENTICATED_DEFAULT_ROUTE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonusState])

  if (isLoading) return <CircularProgress height='80vh' />
  return <View partner={partner} {...getContentByPartner(partner, texts)} />
}

export default EndOfService
