import { useLocation, Navigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { Session, User, useBonusCreation } from '../business'
import { CircularProgress } from './components'

const RequireActiveBonus = ({ children }) => {
  let location = useLocation()
  const { bonusState: bonus } = User.useBonus()

  if (
    location.pathname !== '/end-of-service' &&
    !bonus.isLoading &&
    !Boolean(bonus.data)
  ) {
    return <Navigate to='/end-of-service' state={{ from: location }} replace />
  }
  return children
}

const BonusApplicator = ({ children }) => {
  const { applyBonus } = useBonusCreation()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      await applyBonus()
      setIsLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <CircularProgress />
  return children
}

const RequireAuthenticated = ({ children }) => {
  let location = useLocation()
  const { storeBonusToken } = useBonusCreation()

  storeBonusToken()

  if (!Session.isAuthenticated()) {
    return <Navigate to='/' state={{ from: location }} replace />
  }
  return (
    <BonusApplicator>
      <User.BonusProvider>
        <RequireActiveBonus>{children}</RequireActiveBonus>
      </User.BonusProvider>
    </BonusApplicator>
  )
}

export default RequireAuthenticated
