import { useNavigate } from 'react-router-dom'

import { User, Session } from '../../../../business'
import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import View from './View'

const AccountPage = () => {
  const navigate = useNavigate()

  const { bonusState, partnerState } = User.useBonus()
  const { state: bankCardState } = User.useBankCard()
  const { state: balanceState } = User.useBalance()
  const {
    state: { data: userData },
  } = User.usePersonalData()

  const onClickTopUp = () => {
    navigate('top-up-balance')
  }
  const onClickEditBankCard = () => {
    navigate('bank-card-edition')
  }
  const onClickAddBankCard = () => {
    navigate('bank-card-introduction')
  }
  const onClickUpdatePersonalData = () => {
    navigate('update-personal-data')
  }
  const onClickUpdatePassword = () => {
    navigate('update-password')
  }

  const handleLogout = () => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_LOGOUT,
        },
      }),
    )

    Session.logout()
  }

  return (
    <View
      bankCardData={bankCardState}
      balanceData={balanceState}
      userData={userData}
      bonusData={bonusState}
      partnerName={partnerState?.data?.name}
      onClickTopUp={onClickTopUp}
      onClickEditBankCard={onClickEditBankCard}
      onClickAddBankCard={onClickAddBankCard}
      onClickUpdatePersonalData={onClickUpdatePersonalData}
      onClickUpdatePassword={onClickUpdatePassword}
      onClickLogout={handleLogout}
    />
  )
}

export default AccountPage
