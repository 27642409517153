import { useState, useEffect } from 'react'
import { object, string } from 'yup'

import { Session } from '../../../business'
import { useTexts } from '../../../texts'
import { actionsAppUrl } from '../../../config'
import { decode, encode } from '../../format'

const ACTIONS_APP_PARAMS = {
  TYPE: 'type',
}
const ACTIONS_APP_PARAMS_VALUES = {
  [ACTIONS_APP_PARAMS.TYPE]: {
    REGISTER: 'register',
    LOGIN: 'login',
  },
}
const ACTIONS_APP_PARAM_TYPE_VALUES =
  ACTIONS_APP_PARAMS_VALUES[ACTIONS_APP_PARAMS.TYPE]

const yupDecoded = (obj) => object(obj).json().transform(decode)

export const ACCESS_PARAMS = {
  PARTNER_ID: 'partner_id',
  MEDIA_ID: 'media_id',
  ARTICLE: 'article',
  PAYPER_DEEP_LINK_ON_UNAUTH: 'deep_link_on_unauth',
  REDIRECT_URI: 'redirect_uri',
  REDIRECT_ON_UNAUTH_URI: 'redirect_on_unauth_uri',
  ACTIONS: 'actions',
}

const BASE_REGISTRATION_PARAMS_VALUE_DEFINITION = {
  [ACCESS_PARAMS.REDIRECT_URI]: string(),
  [ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI]: string(),
  [ACCESS_PARAMS.ACTIONS]: string().nullable(),
}

const baseRegistrationSchema = object({
  ...BASE_REGISTRATION_PARAMS_VALUE_DEFINITION,
})

const withRedirectOnUnauthFlagSchema = object({
  ...BASE_REGISTRATION_PARAMS_VALUE_DEFINITION,
  [ACCESS_PARAMS.PAYPER_DEEP_LINK_ON_UNAUTH]: string(),
}).test({
  name: 'one-of-redirect*uri',
  message: `One of the following parameters must be provided: "${ACCESS_PARAMS.REDIRECT_URI}", "${ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI}".`,
  test: (value) =>
    Boolean(value[ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI]) ||
    Boolean(value[ACCESS_PARAMS.REDIRECT_URI]),
})

const articleSchema = yupDecoded({
  id: string(),
  headline: string(),
  url: string(),
})

const fromMediaRegistrationSchema = object({
  [ACCESS_PARAMS.ARTICLE]: articleSchema.default(null).notRequired(),
  [ACCESS_PARAMS.MEDIA_ID]: string().uuid().required(),
  [ACCESS_PARAMS.PAYPER_DEEP_LINK_ON_UNAUTH]: string(),
  [ACCESS_PARAMS.REDIRECT_URI]: string(),
  [ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI]: string(),
  [ACCESS_PARAMS.ACTIONS]: string().nullable(),
})

const fromPartnerRegistrationSchema = object({
  [ACCESS_PARAMS.PARTNER_ID]: string().uuid().required(),
  [ACCESS_PARAMS.PAYPER_DEEP_LINK_ON_UNAUTH]: string(),
  [ACCESS_PARAMS.REDIRECT_URI]: string(),
  [ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI]: string(),
  [ACCESS_PARAMS.ACTIONS]: string().nullable(),
})

const getSchemaForParamRequirements = (param) => {
  switch (param) {
    case ACCESS_PARAMS.PARTNER_ID:
      return fromPartnerRegistrationSchema
    case ACCESS_PARAMS.MEDIA_ID:
      return fromMediaRegistrationSchema
    case ACCESS_PARAMS.PAYPER_DEEP_LINK_ON_UNAUTH:
      return withRedirectOnUnauthFlagSchema
    default:
      return baseRegistrationSchema
  }
}

const ATTRIBUTION_PARAMS = {
  USER_REF_ID: 'user_ref_id',
  GCLID: 'gclid',
  FBCLID: 'fbclid',
  UTM_ID: 'utm_id',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
}

const attributionParamsSchema = object({
  [ACCESS_PARAMS.PARTNER_ID]: string().uuid(),
  [ACCESS_PARAMS.MEDIA_ID]: string().uuid(),
  [ATTRIBUTION_PARAMS.USER_REF_ID]: string().uuid(),
  [ATTRIBUTION_PARAMS.GCLID]: string(),
  [ATTRIBUTION_PARAMS.FBCLID]: string(),
  [ATTRIBUTION_PARAMS.UTM_ID]: string(),
  [ATTRIBUTION_PARAMS.UTM_CAMPAIGN]: string(),
  [ATTRIBUTION_PARAMS.UTM_MEDIUM]: string(),
  [ATTRIBUTION_PARAMS.UTM_SOURCE]: string(),
  [ATTRIBUTION_PARAMS.UTM_TERM]: string(),
  [ATTRIBUTION_PARAMS.UTM_CONTENT]: string(),
})

const getSearchParamsObject = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const obj = {}
  for (const [key, value] of searchParams.entries()) {
    Object.assign(obj, { [key]: value })
  }
  return obj
}

const deleteSearchParams = () => {
  const newUrl = new URL(window.location)
  newUrl.search = ''
  window.location = newUrl
}

const removeUndefinedProps = (obj) =>
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])

const formatAttributionParams = (params) => {
  const UTM_PARAMS = Object.values(ATTRIBUTION_PARAMS).filter((param) =>
    param.startsWith('utm_'),
  )

  const formattedParams = { ...params }
  const utm = {}
  for (const utmParam of UTM_PARAMS) {
    utm[utmParam] = formattedParams[utmParam]
    delete formattedParams[utmParam]
  }
  removeUndefinedProps(formattedParams)
  removeUndefinedProps(utm)
  formattedParams.utm = utm
  return formattedParams
}

const useSearchParams = () => {
  const texts = useTexts()
  const [data, setData] = useState()

  useEffect(() => {
    ;(async () => {
      const searchParamsObject = getSearchParamsObject()

      const validateWithError = async (
        schema,
        errorTitle = texts.getParamsValidationErrorLabel(),
      ) => {
        let registrationData
        try {
          registrationData = await await schema.validate(searchParamsObject, {
            stripUnknown: true,
          })
        } catch (error) {
          console.error(errorTitle, error.message)
          alert(
            errorTitle +
              ` ${error.message} ` +
              texts.getParamsValidationErrorWillDeleteUrl(),
          )
          deleteSearchParams()
        }
        return registrationData
      }

      const validateForGivenParam = async (param) => {
        const schema = getSchemaForParamRequirements(param)
        return await validateWithError(
          schema,
          texts.getParamsValidationErrorLabelByParam(param),
        )
      }

      const setDefaultRedirectOnUnauthUriValue = (registrationParams) => {
        if (
          !Boolean(registrationParams[ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI]) &&
          Boolean(registrationParams[ACCESS_PARAMS.REDIRECT_URI])
        ) {
          registrationParams[ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI] =
            registrationParams[ACCESS_PARAMS.REDIRECT_URI]
        }
      }

      const getRegistrationParams = async () => {
        let registrationParams = {}

        if (Object.hasOwn(searchParamsObject, ACCESS_PARAMS.PARTNER_ID)) {
          registrationParams = await validateForGivenParam(
            ACCESS_PARAMS.PARTNER_ID,
          )
        } else if (Object.hasOwn(searchParamsObject, ACCESS_PARAMS.MEDIA_ID)) {
          registrationParams = await validateForGivenParam(
            ACCESS_PARAMS.MEDIA_ID,
          )
        } else if (
          Object.hasOwn(
            searchParamsObject,
            ACCESS_PARAMS.PAYPER_DEEP_LINK_ON_UNAUTH,
          )
        ) {
          registrationParams = await validateForGivenParam(
            ACCESS_PARAMS.PAYPER_DEEP_LINK_ON_UNAUTH,
          )
        } else {
          registrationParams = await validateWithError(baseRegistrationSchema)
        }

        setDefaultRedirectOnUnauthUriValue(registrationParams)
        return registrationParams
      }

      const getExternalParams = (...internalParams) => {
        const notExternalParamsKeys = internalParams.reduce(
          (acc, params) => acc.concat(Object.keys(params)),
          [],
        )
        const externalParams = { ...searchParamsObject }
        notExternalParamsKeys.forEach((key) => delete externalParams[key])
        return externalParams
      }

      const attributionParams = await validateWithError(attributionParamsSchema)
      const attribution = formatAttributionParams(attributionParams)

      const registrationParams = await getRegistrationParams()

      const externalParams = getExternalParams(
        attributionParams,
        registrationParams,
      )

      const getRedirectBaseUri = () =>
        registrationParams?.[ACCESS_PARAMS.REDIRECT_URI]
      const getRedirectOnUnauthBaseUri = () =>
        registrationParams?.[ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI]

      setData({
        isRedirectUriSet: Boolean(
          registrationParams[ACCESS_PARAMS.REDIRECT_URI],
        ),
        isRedirectOnUnauthUriSet: Boolean(
          registrationParams[ACCESS_PARAMS.REDIRECT_ON_UNAUTH_URI],
        ),
        doRedirectToActionsOnAuth: Boolean(
          registrationParams[ACCESS_PARAMS.ACTIONS],
        ),
        isReferred: Boolean(registrationParams[ACCESS_PARAMS.PARTNER_ID]),
        attribution,
        registrationParams,
        externalParams,
        getRedirectOnUnauthBaseUri,
        getActionsAppUri: (isNewUser) => {
          const redirectUrl = new URL(actionsAppUrl)

          const preservedCurrentSearchParams = { ...registrationParams }
          delete preservedCurrentSearchParams[ACCESS_PARAMS.ARTICLE]

          if (Boolean(registrationParams?.[ACCESS_PARAMS.ARTICLE]?.id)) {
            preservedCurrentSearchParams.state = encode({
              articleId: registrationParams[ACCESS_PARAMS.ARTICLE].id,
            })
          }

          const searchParamsObj = {
            ...preservedCurrentSearchParams,
            ...Session.getAccessParams(),
            [ACTIONS_APP_PARAMS.TYPE]: isNewUser
              ? ACTIONS_APP_PARAM_TYPE_VALUES.REGISTER
              : ACTIONS_APP_PARAM_TYPE_VALUES.LOGIN,
          }

          return `${redirectUrl.origin}${
            redirectUrl.pathname ?? ''
          }?${new URLSearchParams(searchParamsObj).toString()}`
        },
        getRedirectAuthUri: () => {
          const searchParams = new URLSearchParams(Session.getAccessParams())

          return `${getRedirectBaseUri()}?${searchParams.toString()}`
        },
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return data
}

export default useSearchParams
