import { User } from '../../../../business'
import AssociatedMediaDynamicList from './AssociatedMediaDynamicList'
import { PageContent, WhiteBckgFullWidth } from '../../../Layout'
import { PAGE_CONTENT_MARGINS } from '../../../Layout/PageContent'
import Search from './Search'
import Sections from './Sections'
import AddToHomeScreenWizard from './AddToHomeScreenWizard'
import HeroPartner from './HeroPartner'
import Interests from './Interests'
import Magazines from './Magazines'

const Discovery = () => {
  const { partnerState } = User.useBonus()

  return <View partnerData={partnerState} />
}

export const View = ({ partnerData, withoutAddToHomeWizard }) => {
  return (
    <PageContent sx={{ mt: 0 }}>
      <WhiteBckgFullWidth sx={{ py: PAGE_CONTENT_MARGINS.mt }}>
        <HeroPartner partnerData={partnerData} />
        <Search />
      </WhiteBckgFullWidth>
      <Interests partnerData={partnerData} />
      <AssociatedMediaDynamicList />
      <Magazines />
      {!withoutAddToHomeWizard && <AddToHomeScreenWizard />}
      <Sections />
    </PageContent>
  )
}

export default Discovery
